import { AccountViewModel } from '../../@types/generated/AccountViewModel';
import { CartViewModel } from '../../@types/generated/CartViewModel';
import { FavoriteViewModel } from '../../@types/generated/FavoriteViewModel';
import { get } from './../api';

type Response = {
  data: {
    csrf: string;
    cart: CartViewModel;
    favorite: FavoriteViewModel;
    account: AccountViewModel;
    success: boolean;
  };
};

//* Function
export function GetSitedata() {
  return get<Response>('/actions/quantity-app/app/sitedata');
}
