import { CartViewModel } from './../@types/generated/CartViewModel';
import { defineStore } from 'pinia';
import { ref, reactive } from 'vue';

const defaultCart = {
  id: 0,
  reference: '',
  number: '',
  customer: {
    id: 0,
    name: '',
    email: '',
  },
  address: {
    billing: {
      addressLine1: '',
      countryCode: '',
      firstName: '',
      fullName: '',
      id: 0,
      lastName: '',
      attention: '',
      locality: '',
      organization: '',
      phone: '',
      postalCode: '',
      title: '',
      cvr: '',
    },
    shipping: {
      addressLine1: '',
      countryCode: '',
      firstName: '',
      fullName: '',
      id: 0,
      lastName: '',
      attention: '',
      locality: '',
      organization: '',
      phone: '',
      postalCode: '',
      title: '',
      cvr: '',
    },
    billingIsShipping: true,
  },
  delivery: {
    name: '',
    group: '',
  },
  qty: 0,
  lineitems: [],
  recommended: {
    show: false,
    component: '',
    object: '',
    id: '',
    heading: '',
    slides: [],
  },
  additional: {
    notes: '',
    requisition: '',
  },
  progress: {
    remaining: {
      onSale: false,
      isFree: false,
      priceInclVat: '',
      salePriceInclVat: '',
      priceExclVat: '',
      salePriceExclVat: '',
    },
    percent: '0%',
    complete: false,
  },
  total: {
    onSale: false,
    isFree: false,
    priceInclVat: '',
    salePriceInclVat: '',
    priceExclVat: '',
    salePriceExclVat: '',
  },
  subtotal: {
    onSale: false,
    isFree: false,
    priceInclVat: '',
    salePriceInclVat: '',
    priceExclVat: '',
    salePriceExclVat: '',
  },
  shipping: {
    onSale: false,
    isFree: false,
    priceInclVat: '',
    salePriceInclVat: '',
    priceExclVat: '',
    salePriceExclVat: '',
  },
  tax: {
    onSale: false,
    isFree: false,
    priceInclVat: '',
    salePriceInclVat: '',
    priceExclVat: '',
    salePriceExclVat: '',
  },
  gateway: {
    id: 0,
    handle: '',
  },
  request: {
    cancel: '',
    redirect: '',
    return: '',
  },
};

export const useShopStore = defineStore(
  'shop',
  () => {
    //* State
    const showVat = ref(true);
    const cart = reactive<CartViewModel>(defaultCart);

    return {
      showVat,
      cart,
    };
  },
  { persist: true }
);
