import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import { APIResponse } from '../@types/APIResponse';
import { MegaToastViewModel } from '../@types/generated/MegaToastViewModel';

const defaultResponse: MegaToastViewModel = {
  success: false,
  error: false,
  warning: false,
  info: false,
  barcode: '',
  variant: null,
  cart: null,
  // variant: {
  //   id: 0,
  //   name: '',
  //   description: '',
  //   url: '',
  //   sku: '',
  //   images: [],
  //   price: {
  //     onSale: false,
  //     isFree: false,
  //     priceInclVat: '0',
  //     salePriceInclVat: '0',
  //     priceExclVat: '0',
  //     salePriceExclVat: '0',
  //   },
  //   amountPerUnit: '',
  //   stock: {
  //     qty: 0,
  //     expired: false,
  //   },
  //   barcode: '',
  //   selector: [],
  //   tags: [],
  // },
  // cart: {
  //   id: 0,
  //   reference: '',
  //   number: '',
  //   customer: {
  //     id: 0,
  //     name: '',
  //     email: '',
  //   },
  //   address: {
  //     billing: {
  //       addressLine1: '',
  //       countryCode: '',
  //       id: 0,
  //       attention: '',
  //       locality: '',
  //       organization: '',
  //       phone: '',
  //       postalCode: '',
  //       title: '',
  //       cvr: '',
  //       email: '',
  //     },
  //     shipping: {
  //       addressLine1: '',
  //       countryCode: '',
  //       id: 0,
  //       attention: '',
  //       locality: '',
  //       organization: '',
  //       phone: '',
  //       postalCode: '',
  //       title: '',
  //       cvr: '',
  //       email: '',
  //     },
  //     billingIsShipping: true,
  //   },
  //   delivery: {
  //     name: '',
  //     group: '',
  //   },
  //   qty: 0,
  //   lineitems: [],
  //   additional: {
  //     notes: '',
  //     requisition: '',
  //   },
  //   progress: {
  //     remaining: {
  //       onSale: false,
  //       isFree: false,
  //       priceInclVat: '',
  //       salePriceInclVat: '',
  //       priceExclVat: '',
  //       salePriceExclVat: '',
  //     },
  //     percent: '0%',
  //     complete: false,
  //   },
  //   total: {
  //     onSale: false,
  //     isFree: false,
  //     priceInclVat: '',
  //     salePriceInclVat: '',
  //     priceExclVat: '',
  //     salePriceExclVat: '',
  //   },
  //   subtotal: {
  //     onSale: false,
  //     isFree: false,
  //     priceInclVat: '',
  //     salePriceInclVat: '',
  //     priceExclVat: '',
  //     salePriceExclVat: '',
  //   },
  //   shipping: {
  //     onSale: false,
  //     isFree: false,
  //     priceInclVat: '',
  //     salePriceInclVat: '',
  //     priceExclVat: '',
  //     salePriceExclVat: '',
  //   },
  //   tax: {
  //     onSale: false,
  //     isFree: false,
  //     priceInclVat: '',
  //     salePriceInclVat: '',
  //     priceExclVat: '',
  //     salePriceExclVat: '',
  //   },
  //   gateway: {
  //     id: 0,
  //     handle: '',
  //   },
  //   request: {
  //     cancel: '',
  //     redirect: '',
  //     return: '',
  //   },
  // },
  actions: [],
  type: '',
  message: '',
};

export const useGlobalStore = defineStore('global', () => {
  //* State
  const login = ref(0);
  const cart = ref(0);
  const toast = ref(0);
  const response = reactive<MegaToastViewModel>(defaultResponse);

  //* Getters

  //* Actions
  function openLogin() {
    login.value++;
  }

  function openCart() {
    cart.value++;
  }

  function openToast() {
    toast.value++;
  }

  return {
    openLogin,
    login,
    openCart,
    cart,
    toast,
    openToast,
    response,
  };
});
