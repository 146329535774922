import { APIResponse } from '../../@types/APIResponse';
import { MegaToastViewModel } from '../../@types/generated/MegaToastViewModel';
import { VariantViewModel } from '../../@types/generated/VariantViewModel';
import { CartViewModel } from './../../@types/generated/CartViewModel';
import { post } from './../api';
//* Types
type Body = {
  barcode: string;
};

type Response = {
  data: MegaToastViewModel;
};

//* Function
export function AddPurchasableByBarcode(body: Body) {
  return post<Body, Response>(
    '/actions/quantity-shop/cart/add-purchasable-by-barcode',
    body
  );
}
