import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import { FavoriteViewModel } from '../@types/generated/FavoriteViewModel';
import { AccountViewModel } from '../@types/generated/AccountViewModel';

const defaultFavorite = {
  userId: 0,
  favorites: [],
};

const defaultAccount = {
  isLoggedIn: false,
  id: 0,
  name: '',
  company: '',
  email: '',
};

export const useSiteStore = defineStore(
  'site',
  () => {
    //* State
    const account = reactive<AccountViewModel>(defaultAccount);
    const csrf = ref<string>('');
    const siteUrl = ref<string>('');
    const resize = ref<number>(window.innerWidth);
    const favorite = reactive<FavoriteViewModel>(defaultFavorite);

    //* Getters

    //* Actions

    return {
      csrf,
      siteUrl,
      resize,
      favorite,
      account,
    };
  },
  { persist: true }
);
